@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/*023086 137bec 020b20 */

:root{
    /* --color-bg: #1f1f38; */
    --color-bg: #023086;
    --color-bg-variant: #137bec;
    --color-primary: #020b20;
    --color-primary-variant: rgba(77,181,255,0.4);
    --color-white: #fff;
    --color-light: rgba(255,255,255,0.6);

    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 90%;

    --transition: all 400ms ease;
}

html{
    scroll-behavior: smooth;
}
::-webkit-scrollbar{
    display: none;
}

body{
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    /* background: linear-gradient(#e66465, #9198e5); */
    color: var(--color-white);
    line-height: 1.7;
    /* background-image: url(../src/assets/svg-1.svg); TEXTURE */ 
}

/* =================GENERAL STYLES=============== */

.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,h2,h3,h4,h5{
    font-weight: 500;
}

h1{
    font-size: 2.5rem;
    color: var(--color-primary);
}

section{
    margin-top: 8rem;
    /* height: 100vh; */
}

section > h2, section> h5{
    text-align: center;
    color: var(--color-light);

}

section > h2{
    color: var(--color-primary);
}

.text-light{
    color: var(--color-pri);
    font-size: .9rem;
}

a{
    color: var(--color-white);
    transition: var(--transition);
}

a:hover{
    color: var(--color-primary);
}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-white);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover{
    background: var(--color-white);
    color: var(--color-primary);
    background-color: transparent;
}

.btn-primary{
  background: var(--color-primary);
  color: var(--color-white);  
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ================MEDIA QUERIES (MEDIUM DIVICES)================================== */

@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
    
}
/* ================MEDIA QUERIES (SMALL DIVICES)================================== */

@media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-sm);
    }

    section > h2{
        margin-top: 2rem;
    }
    
}


/* Colors */

/* #111110
#92DBD4
#FFFFFF
#3B8A9A */