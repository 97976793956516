header {
  height: 100vh;
  padding-top: 2.5rem;
  overflow: hidden;
}

.header_container {
  text-align: center;
  height: 100%;
  position: relative;
}

.video_background{
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
overflow: hidden;
}

video{
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
}

/*=====================CTA==================*/

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/*=====================HEADER SOCIAL==================*/
.header_social {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
  font-size: 1.2rem;
}

.header_social::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-white);
}

/*=====================HEADER SOCIAL==================*/
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 0 1rem;
 
}

.me img{
  width: 100%;
  border-radius: 12rem 12rem 0 0;
  margin: auto;
}



/*=====================SCROLL DOWN==================*/
.scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ================MEDIA QUERIES (MEDIUM DIVICES)================================== */

@media screen and (max-width: 1024px) {
  .header {
    height: 68vh;
  }
}
/* ================MEDIA QUERIES (SMALL DIVICES)================================== */

@media screen and (max-width: 600px) {
  .header {
    height: 68vh;
  }

  .header_social,
  .scroll_down {
      display: none;
  }
}
