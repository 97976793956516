.portfolio_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio_item{
    background: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio_item:hover{
border-color: var(--primary-variant);
background: transparent;
}

.portfolio_item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio_item h3{
    margin: .5rem 0;
   text-align: left;
}

.portfolio_item-cta{
    height: 3rem;
    display: flex;
    justify-content: end;
    /* gap: 1rem; */
    /* margin-bottom: .5rem; */
    font-size: small;
}




/* ================MEDIA QUERIES (MEDIUM DIVICES)================================== */

@media screen and (max-width: 1024px) {
    .portfolio_container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ================MEDIA QUERIES (SMALL DIVICES)================================== */

@media screen and (max-width: 600px) {
      .portfolio_container{
          grid-template-columns: 1fr;
          gap: 1rem;
      }
   
  }

